// @ts-ignore
import { showModal } from 'common/js/modal/modal';
// @ts-ignore
import { setCookie, isActiveCookie } from 'common/js/utils/cookie';
import ouibounce from './libs/ouibounce.min';
import CountdownTimer from './classes/CountdownTimer';

const domScriptTemplatePopup = document.querySelector<HTMLScriptElement>('script[data-js-popup="exit"]');
const isWindowWidthEnough = () => document.body.clientWidth >= 768;

if (domScriptTemplatePopup) {
    const cookieName = domScriptTemplatePopup.id;
    let countdownTimer: CountdownTimer | null = null;

    ouibounce(false, {
        setCookie: false,
        sitewide: true,
        timer: 0,
        callback: () => {
            if (!isActiveCookie(cookieName) && isWindowWidthEnough()) {
                showModal(
                    domScriptTemplatePopup,
                    (el) => {
                        // @ts-ignore
                        const domCountdownTimerElement = el.querySelector<HTMLElement>('[data-js-countdown]');

                        setCookie(cookieName, 365);

                        if (domCountdownTimerElement) {
                            countdownTimer = new CountdownTimer(domCountdownTimerElement);
                        }
                    },
                    () => {
                        if (countdownTimer) {
                            countdownTimer!.stopTimer();
                        }
                    },
                );
            }
        },
    });
}

export {};
